import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

const Impress = ()  => {
  
  const [loading, setLoading] = useState(true);
    
  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 2500));
      setLoading((loading) => !loading);
    };
      
    loadData();
  }, [])
  
  if (loading) {
      return <img src="/videos/SVG-Animation.mp4" class="preload" alt="Preload" height="100%" />
  }else {
    return ( 
    <>
      <div class="maintext">
            <div class="impresstext">
            <h1>Impressum</h1><br/>
            <p>Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.</p><br/>
            <p>Verantwortlich für die Inhalte:</p><br/>
            <Link to='/' className='social-logo' class="blackBambi">
            BambiGuard
            <img src='../192x192.png' alt="BambiGuard Logo" width="50" height="50"/>
            </Link>
            <p><strong>BambiGuard</strong> - Team</p>
            <p>Projektleiter</p>
            <p>Maximilian Strobl</p><br/>
            <p>
                Am Schuß 56, 
                <br/>
                3653 Weiten, 
                <br/>
                Österreich
            </p> <br/>
            <p>
                <strong>Tel.:</strong>
                <a href="tel:069919271260"> +43 699 1927 1260</a>
                <br/>
                <strong>E-Mail:</strong>
                <a href="mailto:maximilian.strobl@sz-ybbs.ac.at"> maximilian.strobl@sz-ybbs.ac.at</a>
            </p>
            </div>
        </div>
        
        <div className="impresspic1"></div>
      <Footer />
    </>
  );
}
}
export default Impress;