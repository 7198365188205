import React from 'react';
import './Form.css';

function Form() {
  return (
    <div className="FormBox">
        <h1>Ihre Nachricht an uns </h1>
        <form action="https://form.taxi/s/4nbvyvgw" id="ft-form" method="POST" accept-charset="UTF-8">
            <fieldset>
                <label>Name *
                <input type="text" name="Name" required/>
                </label>
                <label>
                E-Mail-Adresse*
                <input type="email" name="E-Mail" required/>
                </label>
                <label>
                Ihre Nachricht*
                <textarea rows="3" name="Nachricht" required></textarea>
                </label>
                <div>
                <label>
                    <input type="checkbox" name="Datenverarbeitung bestätigt" value="Ja" required/>
                    * Ich bestätige, dass ich mit der Verarbeitung meiner Eingaben zum Zwecke der Anfragebearbeitung, einverstanden bin.
                </label>
                </div>
            </fieldset>
            <div class="btns">
                <input type="text" name="_gotcha" value="" className="noinput"/>
                <input type="submit" value="Absenden"/>
            </div>
            </form>

        <div class="container">
            <iframe class="card" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2660.5078797492656!2d15.080648115619507!3d48.17756525635415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47724312895524fd%3A0x50c234a61ba4beb1!2sIT-HTL%20Ybbs!5e0!3m2!1sde!2sat!4v1594544920044!5m2!1sde!2sat" width="850" height="450" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"/>

                <div class="cards-con">
                    <div class="card-addr">
                        <i class="fas fa-map-marked-alt text-primary mb-2"></i>
                        <h4>Adresse</h4>
                        <div>Schulring 6-12, 3370 Ybbs an der Donau</div>
                    </div>
                    <div class="card-em">
                        <i class="fas fa-envelope text-primary mb-2"></i>
                        <h4>Email</h4>
                        <div ><a className="email"href="mailto:bambi.guard@gmail.com">bambi.guard@gmail.com</a></div>
                    </div>
                </div>
        </div>
    </div>
    
  );
}

export default Form;