import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


function Footer() {
  return (
    <div className='footer-container'>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>BambiGuard</h2>
            <HashLink to='/#main'>Was ist BambiGuard?</HashLink>
            <Link to='/AboutUs'>Wer ist BambiGUard?</Link>
          </div>
          <div class='footer-link-items'>
          <h2>Partner</h2>
            <a href='https://www.sz-ybbs.ac.at' target='_blank'>IT-HTL Ybbs</a>
            <a href='https://www.sz-ybbs.ac.at/schulzentrum/vfi' target='_blank'>Verein zur Förderung von Forschung und Innovation</a>
          </div>
        
          <div class='footer-link-items'>
            <h2>Kontakt</h2>
            <Link to='/Contact'>Anfrage</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <a href='https://www.instagram.com/bambi.guard/' target='_blank'>Instagram</a>
            <a href='https://www.facebook.com/bambiguard-103947225423285' target='_blank'>Facebook</a>
            </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
            BambiGuard
            <img src='../192x192.png' alt="BambiGuard Logo" width="50" height="50"/>
            </Link>
          </div>
          <small class='website-rights'>BambiGuard © 2021 |  <Link class="website-rights-impresslink" to="/Impress" > Impressum</Link> </small>
          <div class='social-icons'>
            <a
              class='social-icon-link facebook'
              href='https://www.facebook.com/bambiguard-103947225423285'
              target='_blank' rel='noopener noreferrer'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </a>
            <a
              class='social-icon-link instagram'
              href='https://www.instagram.com/bambi.guard/'
              target='_blank' rel="noopener noreferrer"
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
