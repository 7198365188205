import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Cards from '../Cards';
import {useState, useEffect} from 'react';

const AboutUs = ()  => {
  
  const [loading, setLoading] = useState(true);
    
  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 2500));
      setLoading((loading) => !loading);
    };
      
    loadData();
  }, [])
  
  if (loading) {
      return <img src="/videos/SVG-Animation.mp4" class="preload" alt="Preload" height="100%" />
  }else {
    return ( //<h1 className='aboutUs'>Über uns</h1>;
  <>
      <Cards />
  <Footer />
</> );
}
}
export default AboutUs;
