import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Form from '../Form'
import {useState, useEffect} from 'react';


const Contact = ()  => {
  
  const [loading, setLoading] = useState(true);
    
  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 2500));
      setLoading((loading) => !loading);
    };
      
    loadData();
  }, [])
  
  if (loading) {
      return <img src="/videos/SVG-Animation.mp4" class="preload" alt="Preload" height="100%" />
  }else {
    return ( 
    <>
      <Form/>
      <Footer />
    </>
  );
  
  }
}
export default Contact;
