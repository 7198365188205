import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1 style={{textAlign: 'center'}}>Wer ist BambiGuard?</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'> 
          <ul className='cards__items'>
           <CardItem
              src='images/Team/Teufel.png'
              leadtext='Corporate Identity/ Design, Social Media Marketing'
              text1='Felix befasst sich im Rahmen seiner Arbeit damit, das Projekt zu bewerben, um somit die Aufmerksamkeit der Menschen bezüglich dieses Themas zu erhöhen. Die Generierung dieser Aufmerksamkeit und sozialen Verantwortung erfolgt durch Druckprodukte begleitet von Social-Media-Kanälen und einem Werbevideo.'
              text2='Druckprodukte wie etwa Flyer oder Visitenkarten erfüllen den Zweck Menschen über das Problem Mähtod zu informieren und ihnen einen Kontaktpunkt zu bieten. Social-Media Accounts vertreten das Projekt nach außen und erhöhen die Aufmerksamkeit und sollen ein gewisses Bewusstseinsbild in den Menschen schaffen. Ebenso wie die Social-Media Accounts dient das Werbevideo dazu, um die Aufmerksamkeit zu erhöhen und ZuschauerInnen zu informieren. Felix ist für die Erstellung all dieser Komponenten verantwortlich und verwaltet und aktualisiert diese laufend anhand der aktuellen Fakten.'
              text3='Die Erstellung von Werbeartikeln oder Videos ist heutzutage von besonders großer Bedeutung und stützt sich auf Themengebieten der Gestaltung von Grafiken und Medien. Prägnante und informative Werbemedien sind sehr gefragt und besitzen in der Bewusstseins- und Aufmerksamkeitsgenerierung einen sehr hohen Stellenwert.'
              name='Felix Teufel'
              path='/'
            />
            <CardItem
              src='images/Team/Strobl.png'
              leadtext='Projektleitung, UI/UX Design, Frontend Entwicklung, Webentwicklung'
              text1='Maximilians Aufgabenbereich liegt in der Konzeptionierung und Erstellung einer Benutzeroberfläche für unsere App, zusätzlich erstellt und verwaltet er unsere Webseite und ist für die iOS Version unserer App zuständig.
              Wichtige Faktoren welche Maximilian zu beachten hat sind etwa die einfache und intuitive Bedienung der Webseite und App und das Einhalten der von Apple und Android vorgegebenen Design und Aufbau Richtlinien.' 
              text2='Da unsere Webseite unseren Projektfortschritt widerspiegelt und uns repräsentiert ist es wichtig diese zu verwalten und mit Beiträgen zu befüllen, um BesucherInnen stets auf dem Laufenden zu halten. Maximilian hat unsere Webseite erstellt und verwaltet diese auch dementsprechend.'
              text3='Um den Erfolg des Teams zu steigern, liegt ein weiterer Aufgabenpunkt von Maximilian darin unser Team zu leiten und alle Projektmanagement relevanten Themen zu kontrollieren und zu verwalten.
              Die Kompetenz des Projektmanagements und der Erstellung und Verwaltung einer Webseite sind Eigenschaften, welche im Informationstechnischen Bereich stets gefragt sind und wichtig für alle Gruppen, welche an einem Projekt arbeiten.'
              name='Maximilian Strobl'
              path='/'
            />
            <CardItem
              src='images/Team/Losbichler.png'
              leadtext='Bilderkennung, Backend Entwicklung'
              text1='Clemens befasst sich intensiv mit der Entwicklung unserer App und achtet darauf, dass alle Nutzdaten von der Drohne zu den Smartphones der Pilotin/des Piloten und der HelferInnen gelangen. Ein großes Augenmerk wird dabei auf die automatische Erkennung der Wildtiere gelegt, um somit den Arbeitsfluss der PilotInnen zu erleichtern und zu beschleunigen.'
              text2='Aufgaben welche Clemens in der App Entwicklung erwarten sind etwa die Herstellung einer aktiven Verbindung zwischen Drohne und Smartphone, das Anlegen der Oberfläche mit welcher der/die BenutzerIn interagiert und die Programmierung einer Logik, welche die empfangenen Videodaten analysieren und auswerten.'
              text3='Dieser Aufgabenbereich ist essenziell für den Erfolg von BambiGuard und äußerst vielseitig. In einer Zeit, in welcher das Digitale Zeitalter bereits einen großen Teil des Lebens der Menschen eingenommen hat ist die Kompetenz des Programmierens umso wichtiger und gewinnt zunehmend an Bedeutung.'
              name='Clemens Losbichler'
              path='/'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
