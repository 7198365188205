import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import {useState, useEffect} from 'react';


const Home = () => {
  
  const [loading, setLoading] = useState(true);
    
  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 2500));
      setLoading((loading) => !loading);
    };
      
    loadData();
  }, [])
  
  if (loading) {
      return <img src="/videos/SVG-Animation.mp4" class="preload" alt="Preload" height="100%" />
  }
  else {
      return(  
      <>
      <HeroSection />
      
      <div id="maintop"></div>
      
      <div className="content" >
      <div className='contenttyp1'>
        <div className='contentelements'>
          <div className='welcometext'>
            <h1>Herzlich Willkommen ! </h1>  
            <br/>
            <p>Auf unserer offiziellen Website findest du alle aktuellen Informationen zu unserer Diolomarbeit. Falls du dich für den Schutz von Wildtieren interessierst, kannst du noch gerne in unseren Beiträgen stöbern, um mehr Informationen zu erhalten. Wir halten unsere Website stets aktuell und erstellen Beiträge zu interessanten und aktuellen Themen, welche unsere Diplomarbeit betreffen.</p>
            <br/>
            <p>Aktuell befassen wir uns mit der Programmierung der iOS und Android App, zusätzlich wird noch an unserem Infopaket gearbeitet und die Social Media Kanäle verwaltet.</p>
          </div>
        </div> 
      </div>
    </div>
    
    <div className="sectionpic1" id="main"></div>

    <div className="content">
      <div className='contenttyp1'>
      <div className='contentelements'>
          <div className="contenttext">
            <h1>Unsere Aufgaben und Ziele</h1>  
            <br/>
            <p>Unsere Aufgabe und unser Ziel ist es, ein System zu entwickeln, welches LandwirtInnen Abhilfe in der Suche nach Wildtieren in zu mähenden Feldern schafft. Mähtode sind vermeidbar und können bei vermehrtem Aufkommen zu rechtlichen Konsequenzen führen. Das Tierwohl ist ein großer Motivationsfaktor unserer Arbeit und muss nicht mit zusätzlichem Aufwand, wie etwa dem Abgehen des Feldes verbunden sein, da dies oft sehr zeitintensiv ist.</p>
            <br/>
            <p>Unser Konzept ist eine Innovation in den Bereichen der Effektivität und Aufwandsminderung, da durch unser System zwischen Piloten und Helfer unterschieden wird. Der Pilot muss sich lediglich auf den Flug der Drohne konzentrieren und die Helfer werden per Navigator direkt zu potenziellen Wildtieren geleitet. Die automatische Erkennung der Wildtiere beschleunigt zusätzlich den Arbeitsprozess.</p>
            <br/>
            <p>Die Bündelung dieser Funktionen in einer Anwendung einzigartig und kann vielseitig verwendet werden. </p>
          </div>
        </div>
        </div> 
    </div>

    <div className="sectionpic2"></div>

    <div className="content">
      <div className='contenttyp2'>
        <div className='contentelements'>
        <div className="contenttext2">
            <h1>Fakten zum Thema Mähtod </h1>  
            <br/>
            <p>Laut Daten des Tierschutzvereins Österreich verenden jährlich 25.000 Rehkitze durch Mähwerke. Jungtiere werden von ihren Müttern in hohem Gras abgelegt, um diese vor natürlichen Fressfeinden zu schützen. Da der Fluchtreflex in jungem Alter noch nicht vorhanden ist, flüchten die Tiere auch nicht vor Mähwerken.</p>
            <br/>
            <p>Auch ohne einer Drohne ist es möglich, Mähtode effektiv zu verhindern. So können etwa bereits am Tag vor den Mäharbeiten Vogelscheuchen aufgestellt werden, um die Tiere zu vergrämen. Kurz vor den Mäharbeiten kann zusätzlich gemeinsam mit Jägerinnen und Jägern und ihren Suchhunden das Feld nach Wildtieren abgesucht werden.</p>
          </div>
        </div> 
      </div>
    </div>
      
    <div className="sectionpic3"></div>

    <div className="content">
      <div className='contenttyp1'>
        <div className='contentelements'>
          <div className="contenttext">
            <h1>Wichtige Informationen für BesucherInnen</h1>  
            <br/>
            <p>Die Diplomarbeit BambiGuard erstellt einen Prototyp, um Mähtode zu verhindern, es handelt sich hierbei noch um kein marktfähiges oder erwerbliches Produkt. Die erstellte App kann noch nicht heruntergeladen oder verwendet werden. Falls du Fragen an uns hast, kannst du dich ganz einfach bei uns über unsere E-Mail bambiguard@gmail.com melden oder nutz einfach das Kontaktformular.</p>
          <br/>
          <br/>
          <p>Besuche uns auf unseren Social-Media Kanälen um noch mehr aktuelle Informationen zu unserer Arbeit zu erhalten. Du findest uns auf Facebook und Instagram, wir freuen uns auf deinen Besuch.</p>
          </div>
        </div> 
      </div>
    </div>
    
    <div className="sectionpic4"></div>

      <Footer />
    </>
  );
  }
}

export default Home;
