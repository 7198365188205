import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import Popup from './Popup';
import {useState} from 'react';
import { HashLink } from 'react-router-hash-link';

function HeroSection() {
  const [buttonPopup, setButtonPopop] = useState(false);

  return (
    <div className='hero-container'>
      <video src='/videos/Headervideo.mp4' autoPlay loop muted />
      <h1>BAMBIGUARD</h1>
      <p>Eine intelligente Rehkitzrettungsapp</p>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          Erfahre mehr!
        </Button>
        
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={() => setButtonPopop(true)}
        >
          Infovideo <i className='far fa-play-circle' />
        </Button>
        <Popup trigger={buttonPopup} setTrigger={setButtonPopop}>
          <h3>Infovideo in Arbeit</h3><br></br>
          <p>Das Werbevideo für BambiGuard befindet sich gerade in Arbeit und wird zu einem späterem Zeitpunkt auf der Website veröffentlicht. Wir bitten um ein wenig Geduld ;) </p>
        </Popup>
      </div>
    </div>
  );
}

export default HeroSection;
